/* eslint-disable */

var game;
var gTankImageURL;
var gEventCb;

export const EVENT_GAME_STARTED = 'gameStarted';
export const EVENT_GAME_WON = 'gameWon';
export const EVENT_GAME_OVER = 'gameOver';

const SHIP_SCALE = 100.0 / 256.0;

var EnemyTank = function (index, game, player, bullets) {

    var x = game.world.randomX;
    var y = game.world.randomY;

    this.game = game;
    this.health = 3;
    this.player = player;
    this.bullets = bullets;
    this.fireRate = 1000;
    this.nextFire = 0;
    this.alive = true;

    this.shadow = game.add.sprite(x, y, 'enemy', 'shadow');
    this.tank = game.add.sprite(x, y, 'enemy', 'tank1');
    this.turret = game.add.sprite(x, y, 'enemy', 'turret');

    this.shadow.anchor.set(0.5);
    this.tank.anchor.set(0.5);
    this.turret.anchor.set(0.3, 0.5);

    this.tank.name = index.toString();
    game.physics.enable(this.tank, Phaser.Physics.ARCADE);
    this.tank.body.immovable = false;
    this.tank.body.collideWorldBounds = true;
    this.tank.body.bounce.setTo(1, 1);

    this.tank.angle = game.rnd.angle();

    game.physics.arcade.velocityFromRotation(this.tank.rotation, 100, this.tank.body.velocity);

};

EnemyTank.prototype.damage = function() {

    this.health -= 1;

    if (this.health <= 0)
    {
        this.alive = false;

        this.shadow.kill();
        this.tank.kill();
        this.turret.kill();

        return true;
    }

    return false;

}

EnemyTank.prototype.update = function() {

    this.shadow.x = this.tank.x;
    this.shadow.y = this.tank.y;
    this.shadow.rotation = this.tank.rotation;

    this.turret.x = this.tank.x;
    this.turret.y = this.tank.y;
    this.turret.rotation = this.game.physics.arcade.angleBetween(this.tank, this.player);

    if (this.game.physics.arcade.distanceBetween(this.tank, this.player) < 300)
    {
        if (this.game.time.now > this.nextFire && this.bullets.countDead() > 0)
        {
            this.nextFire = this.game.time.now + this.fireRate;

            var bullet = this.bullets.getFirstDead();

            bullet.reset(this.turret.x, this.turret.y);

            bullet.rotation = this.game.physics.arcade.moveToObject(bullet, this.player, 500);
        }
    }

};

EnemyTank.prototype.disablePhysics = function() {
    this.tank.body.moves = false;
    this.tank.body.velocity.setTo(0, 0);
    this.tank.body.angularVelocity = 0;
    this.tank.body.allowRotation = false;
}

EnemyTank.prototype.reset = function() {
    var x = game.world.randomX;
    var y = game.world.randomY;

    this.health = 3;
    this.fireRate = 1000;
    this.nextFire = 0;
    this.alive = true;

    if (!this.tank.alive) {
        this.tank.revive();
    }

    if (!this.shadow.alive) {
        this.shadow.revive();
    }

    if (!this.turret.alive) {
        this.turret.revive();
    }

    this.tank.position.x = x;
    this.tank.position.y = y;
    this.tank.angle = game.rnd.angle();

    game.physics.enable(this.tank, Phaser.Physics.ARCADE);
    this.tank.body.moves = true;

    game.physics.arcade.velocityFromRotation(this.tank.rotation, 100, this.tank.body.velocity);
}

export function startGame (tankImageURL, eventCb) {
    game = new Phaser.Game(
        800,
        600,
        Phaser.AUTO,
        'tank-canvas-parent',
        {
            preload: preload,
            create: create,
            update: update,
            render: render
        }
    );
    gTankImageURL = tankImageURL;
    gEventCb = eventCb;
}

function fireEvent(eventType) {
    if (gEventCb) {
        gEventCb(eventType);
    }
}

function preload () {
    game.load.crossOrigin = true;

    game.load.atlas('tank', 'assets/tanks.png', 'assets/tanks.json');
    game.load.atlas('enemy', 'assets/enemy-tanks.png', 'assets/tanks.json');
    game.load.image('logo', 'assets/logo.png');
    game.load.image('bullet', 'assets/tank-bullet.png');
    game.load.image('earth', 'assets/scorched_earth.png');
    game.load.spritesheet('kaboom', 'assets/explosion.png', 64, 64, 23);

    if (gTankImageURL) {
        game.load.image('test-ship', gTankImageURL);
    } else {
        game.load.image('test-ship', 'assets/test-ship.png');
    }
}

var land;

var shadow;
// var tank;
// var turret;
var ship;

var enemies;
var enemyBullets;
var enemiesTotal = 0;
var enemiesAlive = 0;
var explosions;

var logo;

var currentSpeed = 0;
var cursors;

var bullets;
var fireRate = 100;
var nextFire = 0;

let stateText;

let gameOver = false;
let gameWon = false;

const startingLives = 10;
var playerLives = startingLives;

var playerLastHitTime = 0;
var playerBulletTint = 0xffffff;

function create () {
    //  Resize our game world to be a 2000 x 2000 square
    game.world.setBounds(-1000, -1000, 2000, 2000);

    //  Our tiled scrolling background
    land = game.add.tileSprite(0, 0, 800, 600, 'earth');
    land.fixedToCamera = true;

    //  The base of our tank
    // tank = game.add.sprite(0, 0, 'tank', 'tank1');
    // tank.anchor.setTo(0.5, 0.5);
    // tank.animations.add('move', ['tank1', 'tank2', 'tank3', 'tank4', 'tank5', 'tank6'], 20, true);

    //  This will force it to decelerate and limit its speed
    // game.physics.enable(tank, Phaser.Physics.ARCADE);
    // tank.body.drag.set(0.2);
    // tank.body.maxVelocity.setTo(400, 400);
    // tank.body.collideWorldBounds = true;

    //  Finally the turret that we place on-top of the tank body
    // turret = game.add.sprite(0, 0, 'tank', 'turret');
    // turret.anchor.setTo(0.3, 0.5);

    ship = game.add.sprite(0, 0, 'test-ship');
    ship.scale.setTo(SHIP_SCALE, SHIP_SCALE);
    ship.anchor.setTo(0.5, 0.5);

    game.physics.enable(ship, Phaser.Physics.ARCADE);
    ship.body.drag.set(0.2);
    ship.body.maxVelocity.setTo(400, 400);
    ship.body.collideWorldBounds = true;

    //  The enemies bullet group
    enemyBullets = game.add.group();
    enemyBullets.enableBody = true;
    enemyBullets.physicsBodyType = Phaser.Physics.ARCADE;
    enemyBullets.createMultiple(100, 'bullet');
    
    enemyBullets.setAll('anchor.x', 0.5);
    enemyBullets.setAll('anchor.y', 0.5);
    enemyBullets.setAll('outOfBoundsKill', true);
    enemyBullets.setAll('checkWorldBounds', true);

    //  Create some baddies to waste :)
    enemies = [];

    enemiesTotal = 20;
    enemiesAlive = 20;

    for (var i = 0; i < enemiesTotal; i++)
    {
        enemies.push(new EnemyTank(i, game, ship, enemyBullets));
    }

    //  A shadow below our tank
    shadow = game.add.sprite(0, 0, 'tank', 'shadow');
    shadow.anchor.setTo(0.5, 0.5);

    //  Our bullet group
    bullets = game.add.group();
    bullets.enableBody = true;
    bullets.physicsBodyType = Phaser.Physics.ARCADE;
    bullets.createMultiple(30, 'bullet', 0, false);
    bullets.setAll('anchor.x', 0.5);
    bullets.setAll('anchor.y', 0.5);
    bullets.setAll('outOfBoundsKill', true);
    bullets.setAll('checkWorldBounds', true);

    //  Explosion pool
    explosions = game.add.group();

    for (var i = 0; i < 10; i++)
    {
        var explosionAnimation = explosions.create(0, 0, 'kaboom', [0], false);
        explosionAnimation.anchor.setTo(0.5, 0.5);
        explosionAnimation.animations.add('kaboom');
    }

    // tank.bringToTop();
    // turret.bringToTop();

    stateText = game.add.text(game.camera.width / 2, game.camera.height / 2, '', {
        font: '84px Arial',
        fill: '#fff',
    });
    stateText.anchor.setTo(0.5, 0.5);
    stateText.fixedToCamera = true;
    stateText.visible = false;

    ship.bringToTop();

    logo = game.add.sprite(0, 200, 'logo');
    logo.fixedToCamera = true;

    game.input.onDown.add(removeLogo, this);

    // game.camera.follow(tank);
    game.camera.follow(ship);
    game.camera.deadzone = new Phaser.Rectangle(150, 150, 500, 300);
    game.camera.focusOnXY(0, 0);

    cursors = game.input.keyboard.createCursorKeys();

    fireEvent(EVENT_GAME_STARTED);
}

function removeLogo () {

    game.input.onDown.remove(removeLogo, this);
    logo.kill();

}

function update () {
    if (gameOver || gameWon) {
        return;
    }

    game.physics.arcade.overlap(enemyBullets, ship, bulletHitPlayer, null, this);

    enemiesAlive = 0;

    for (var i = 0; i < enemies.length; i++)
    {
        if (enemies[i].alive)
        {
            enemiesAlive++;
            // game.physics.arcade.collide(tank, enemies[i].tank);
            game.physics.arcade.collide(ship, enemies[i].tank);
            game.physics.arcade.overlap(bullets, enemies[i].tank, bulletHitEnemy, null, this);
            enemies[i].update();
        }
    }

    if (cursors.left.isDown)
    {
        //ship.position.x -= 4;
        // ship.angle -= 4;
        // tank.angle -= 4;
    }
    else if (cursors.right.isDown)
    {
        //ship.position.x += 4;
        //tank.angle += 4;
        // ship.angle += 4;
    }

    if (cursors.up.isDown)
    {
        //  The speed we'll travel at
        currentSpeed = 300;
    }
    else
    {
        if (currentSpeed > 0)
        {
            currentSpeed -= 4;
        }
    }

    if (currentSpeed > 0)
    {
        // game.physics.arcade.velocityFromRotation(tank.rotation, currentSpeed, tank.body.velocity);
        game.physics.arcade.velocityFromRotation(ship.rotation, currentSpeed, ship.body.velocity);
    }

    land.tilePosition.x = -game.camera.x;
    land.tilePosition.y = -game.camera.y;

    //  Position all the parts and align rotations
    shadow.x = ship.x;
    shadow.y = ship.y;
    shadow.rotation = ship.rotation;

    // turret.x = ship.x;
    // turret.y = ship.y;

    ship.rotation = game.physics.arcade.angleToPointer(ship) + 20.4;

    if (game.input.activePointer.isDown)
    {
        //  Boom!
        fire();
    }

    if (enemiesAlive === 0) {
        winGame();
    }
}

function bulletHitPlayer (player, bullet) {

    bullet.kill();

    if (game.time.now - playerLastHitTime > 1000) {
        playerLastHitTime = game.time.now;
        playerLives--;

        if (playerLives == 0) {
            gameLost();
        }
    }
}

function gameLost() {
    console.log('Game Over');

    gameOver = true;

    let explosionAnimation = explosions.getFirstExists(false);
    explosionAnimation.reset(ship.x, ship.y);
    explosionAnimation.play('kaboom', 30, false, true);

    ship.kill();
    shadow.kill();

    for (var i = 0; i < enemies.length; i++)
    {
        if (enemies[i].alive)
        {
            enemies[i].disablePhysics();
        }
    }

    stateText.text = 'Game Over';
    stateText.visible = true;

    fireEvent(EVENT_GAME_OVER);
}

function winGame () {
    console.log('You Win!');

    gameWon = true;

    ship.body.moves = false;
    shadow.kill();
    // shadow.body.moves = false;

    for (var i = 0; i < enemies.length; i++)
    {
        if (enemies[i].alive)
        {
            enemies[i].disablePhysics();
        }
    }

    stateText.text = 'You Win!';
    stateText.visible = true;

    fireEvent(EVENT_GAME_WON);
}

function bulletHitEnemy (tank, bullet) {

    bullet.kill();

    var destroyed = enemies[tank.name].damage();

    if (destroyed)
    {
        var explosionAnimation = explosions.getFirstExists(false);
        explosionAnimation.reset(tank.x, tank.y);
        explosionAnimation.play('kaboom', 30, false, true);
    }
}

function fire () {

    if (game.time.now > nextFire && bullets.countDead() > 0)
    {
        nextFire = game.time.now + fireRate;

        var bullet = bullets.getFirstExists(false);

        bullet.reset(ship.x, ship.y);
        bullet.tint = playerBulletTint;
        // bullet.reset(turret.x, turret.y);

        bullet.rotation = game.physics.arcade.moveToPointer(bullet, 1000, game.input.activePointer, 500);
    }

}

function render () {

    // game.debug.text('Active Bullets: ' + bullets.countLiving() + ' / ' + bullets.length, 32, 32);
    game.debug.text('Lives: ' + playerLives, 32, 32);
    game.debug.text('Enemies: ' + enemiesAlive + ' / ' + enemiesTotal, 32, 64);

}

export function restart () {
    for (var i = 0; i < enemies.length; i++)
    {
        enemies[i].reset();
    }
    shadow.revive();
    ship.revive();

    game.physics.enable(ship, Phaser.Physics.ARCADE);

    ship.position.setTo(0, 0);

    ship.body.moves = true;

    stateText.text = '';
    stateText.visible = false;

    playerLives = startingLives;
    gameOver = false;
    gameWon = false;

    fireEvent(EVENT_GAME_STARTED);
}

export function setMedal (medal) {
    playerLives += medal * startingLives;
    playerBulletTint = [0xff0000, 0x00ff00, 0x0000ff][medal-1];
}
