import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAccount } from 'wagmi';

interface IRequireAuth {
  children: any;
}

const RequireAuth: React.FC<IRequireAuth> = ({ children }) => {
  const [{ data: accountData }] = useAccount({
    fetchEns: true,
  });

  if (accountData?.address === undefined || accountData.address === null) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default RequireAuth;
