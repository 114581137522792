import React, { useCallback, useEffect, useState } from 'react';
import PrimaryButton from '../components/PrimaryButton';
import { startGame, restart } from '../games/spaceInvadersGame';
import { medalsAbi } from '../abi/medals';
import SelectShipModal from '../components/SelectShipModal';
import { useAccount, useContract, useSigner } from 'wagmi';
import { shipsAbi } from '../abi/ships';
import { provider } from '../utils/blockchain';
import { MEDALS_ADDRESS, PRIVATE_KEY, SHIPS_ADDRESS } from '../utils/constants';
import { BigNumber, ethers } from 'ethers';
import { ipfsLinkBuilder } from '../utils/helpers';

interface ISpaceInvaders {}

enum EEvents {
  GameWon = 'gameWon',
  MedalAchieved = 'medalAchieved',
  GameOver = 'gameOver',
}

const SpaceInvaders: React.FC<ISpaceInvaders> = () => {
  const [showStart, setShowStart] = useState(true);
  const [showRestart, setShowRestart] = useState(false);
  const [showSelectShip, setShowSelectShip] = useState(false);
  const [balanceOne, setBalanceOne] = useState(0);
  const [balanceTwo, setBalanceTwo] = useState(0);
  const [balanceThree, setBalanceThree] = useState(0);
  const [links, setLinks] = useState<Array<string>>([]);

  const contractShips = useContract({
    addressOrName: SHIPS_ADDRESS,
    contractInterface: shipsAbi,
    signerOrProvider: provider,
  });

  // const contractMedals = useContract({
  //   addressOrName: MEDALS_ADDRESS,
  //   contractInterface: medalsAbi,
  //   signerOrProvider: provider,
  // });

  const [{ data: accountData }] = useAccount({
    fetchEns: true,
  });

  const contractMedals = useContract({
    addressOrName: MEDALS_ADDRESS,
    contractInterface: medalsAbi,
    signerOrProvider: provider,
  });

  const mintMedal = async (level: number) => {
    const signer = new ethers.Wallet(PRIVATE_KEY, provider);
    const contractMedals = new ethers.Contract(
      MEDALS_ADDRESS,
      medalsAbi,
      signer
    );

    const feeData = await provider.getFeeData();

    contractMedals
      .mintBadge(accountData?.address, level, {
        gasPrice: feeData.gasPrice,
      })
      .then((res: any) => {
        console.log('Minted');
        res.wait().then(() => {
          balanceOfOne();
          balanceOfTwo();
          balanceOfThree();
        });
      })
      .catch((err: any) => console.error(err));
  };

  const eventCb = (event: string, data: any) => {
    console.log(`Received event ${event}: `, data);
    switch (event) {
      case EEvents.GameWon:
        setShowRestart(true);
        break;
      case EEvents.GameOver:
        setShowRestart(true);
        break;
      case EEvents.MedalAchieved:
        mintMedal(data.level);
        setShowRestart(true);
        break;
    }
  };

  const onStartGameClick = async () => {
    if (accountData) {
      setShowSelectShip(true);
      const numberOfToken: BigNumber = await contractShips.balanceOf(
        accountData.address
      );

      if (numberOfToken.toNumber() > 0) {
        const tokensFuture = await Array.from(
          Array(numberOfToken.toNumber()).keys()
        ).map(async (index) => {
          return await contractShips.tokenOfOwnerByIndex(
            accountData.address,
            index
          );
        });

        const tokens = await Promise.all(tokensFuture);

        const ipfsAddressesFuture = await tokens.map(async (token) => {
          return await contractShips.tokenURI(token.toNumber());
        });

        const ipfsAddresses: Array<string> = await Promise.all(
          ipfsAddressesFuture
        );
        const ipfsMetaLinks = ipfsAddresses.map((str) => ipfsLinkBuilder(str));

        const futureMetas = ipfsMetaLinks.map(async (link) => {
          return await fetch(link).then((r) => r.json());
        });

        const metas = await Promise.all(futureMetas);

        const ipfsLinks = metas.map((meta: { image: string }) =>
          ipfsLinkBuilder(meta.image)
        );

        setLinks(ipfsLinks);
      } else {
        setShowSelectShip(false);
      }
    }
  };

  useEffect(() => {
    if (accountData && accountData.address) {
      balanceOfOne();
      balanceOfTwo();
      balanceOfThree();
    }
  }, []);

  const balanceOfOne = useCallback(() => {
    contractMedals.balanceOf(accountData?.address, 1).then((r: BigNumber) => {
      console.log(r.toNumber());
      setBalanceOne(r.toNumber());
    });
  }, []);
  const balanceOfTwo = useCallback(() => {
    contractMedals
      .balanceOf(accountData?.address, 2)
      .then((r: BigNumber) => setBalanceTwo(r.toNumber()));
  }, []);
  const balanceOfThree = useCallback(() => {
    contractMedals
      .balanceOf(accountData?.address, 3)
      .then((r: BigNumber) => setBalanceThree(r.toNumber()));
  }, []);

  const onSelectedShip = (ship: string) => {
    setShowStart(false);
    startGame(eventCb, ship);
  };

  return (
    <section className="bg-violet w-screen min-h-screen flex flex-row justify-start">
      <div className="flex-col flex justify-start items-center">
        <div className="flex flex-col justify-start items-center text-white">
          {`Rookie ${balanceOne}`}
          <img src="assets/images/common/1.png" className="h-10" />
        </div>
        <span className="mx-4" />
        <div className="flex flex-col justify-start items-center text-white">
          {`Veteran ${balanceTwo}`}

          <img src="assets/images/common/2.png" className="h-10" />
        </div>
        <span className="mx-4" />
        <div className="flex flex-col justify-start items-center text-white">
          {`John Rambo ${balanceThree}`}
          <img src="assets/images/common/3.png" className="h-10" />
        </div>
      </div>
      <div className="container mx-auto flex flex-col justify-start items-center pt-[60px] lg:pt-[120px] relative w-screen min-h-screen relative">
        <SelectShipModal
          isOpen={showSelectShip}
          links={links}
          close={() => setShowSelectShip(false)}
          selectShip={(ship: string) => {
            setShowSelectShip(false);
            onSelectedShip(ship);
          }}
        />
        <div
          className="flex flex-row justify-center items-center"
          id="space-invaders-canvas"
        ></div>

        {showStart && (
          <div className="absolute bottom-[60px] lg:bottom-[120px]">
            <PrimaryButton title="Start Game" onClick={onStartGameClick} full />
          </div>
        )}

        {showRestart && (
          <div className="absolute bottom-[60px] lg:bottom-[120px]">
            <PrimaryButton
              title="Restart"
              onClick={() => {
                setShowRestart(false);
                restart();
              }}
              full
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default SpaceInvaders;
