import { BigNumber, ethers } from 'ethers';
import React, { useCallback, useEffect, useState } from 'react';
import { useAccount, useContract, useSigner } from 'wagmi';
import { shipsAbi } from '../abi/ships';
import PrimaryButton from '../components/PrimaryButton';
import SelectShipModal from '../components/SelectShipModal';
import { provider } from '../utils/blockchain';
import {
  MEDALS_ADDRESS,
  PRIVATE_KEY,
  PUBLIC_ADDRESS,
  SHIPS_ADDRESS,
} from '../utils/constants';
import { ipfsLinkBuilder } from '../utils/helpers';
import {
  startGame,
  setMedal,
  restart,
  EVENT_GAME_OVER,
  EVENT_GAME_STARTED,
  EVENT_GAME_WON,
} from '../games/tanks';
import { medalsAbi } from '../abi/medals';
import { Triangle } from 'react-loader-spinner';

interface ITank {}

const Tank: React.FC<ITank> = () => {
  const [showStart, setShowStart] = useState(true);
  const [showMedals, setShowMedals] = useState(false);
  const [showRestart, setShowRestart] = useState(false);
  const [showSelectShip, setShowSelectShip] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedShip, setSelectedShip] = useState('');
  const [balanceOne, setBalanceOne] = useState(0);
  const [balanceTwo, setBalanceTwo] = useState(0);
  const [balanceThree, setBalanceThree] = useState(0);
  const [links, setLinks] = useState<Array<string>>([]);

  const contractShips = useContract({
    addressOrName: SHIPS_ADDRESS,
    contractInterface: shipsAbi,
    signerOrProvider: provider,
  });

  const [{ data }, getSigner] = useSigner();

  const contractMedals = useContract({
    addressOrName: MEDALS_ADDRESS,
    contractInterface: medalsAbi,
    signerOrProvider: provider,
  });

  const [{ data: accountData }] = useAccount({
    fetchEns: true,
  });

  const onStartGameClick = async () => {
    if (accountData) {
      setShowSelectShip(true);
      const numberOfToken: BigNumber = await contractShips.balanceOf(
        accountData.address
      );

      if (numberOfToken.toNumber() > 0) {
        const tokensFuture = await Array.from(
          Array(numberOfToken.toNumber()).keys()
        ).map(async (index) => {
          return await contractShips.tokenOfOwnerByIndex(
            accountData.address,
            index
          );
        });

        const tokens = await Promise.all(tokensFuture);

        const ipfsAddressesFuture = await tokens.map(async (token) => {
          return await contractShips.tokenURI(token.toNumber());
        });

        const ipfsAddresses: Array<string> = await Promise.all(
          ipfsAddressesFuture
        );
        const ipfsMetaLinks = ipfsAddresses.map((str) => ipfsLinkBuilder(str));

        const futureMetas = ipfsMetaLinks.map(async (link) => {
          return await fetch(link).then((r) => r.json());
        });

        const metas = await Promise.all(futureMetas);

        const ipfsLinks = metas.map((meta: { image: string }) =>
          ipfsLinkBuilder(meta.image)
        );

        setLinks(ipfsLinks);
      } else {
        setShowSelectShip(false);
      }
    }
  };

  const onSelectedShip = (ship: string) => {
    setShowStart(false);
    setShowMedals(true);
    setSelectedShip(ship);
  };

  const selectedPowerUp = async (name: number) => {
    setIsLoading(true);
    const signer = new ethers.Wallet(PRIVATE_KEY, provider);
    const contract = new ethers.Contract(MEDALS_ADDRESS, medalsAbi, signer);

    const feeData = await provider.getFeeData();

    const userSigner = await accountData?.connector?.getSigner();
    const medalSignedContract = new ethers.Contract(
      MEDALS_ADDRESS,
      medalsAbi,
      userSigner
    );

    const medalAmount: BigNumber = await contractMedals.balanceOf(
      accountData?.address,
      name
    );

    if (medalAmount.toNumber() > 0) {
      const isApproved: boolean = await contractMedals.isApprovedForAll(
        accountData?.address,
        signer.address
      );

      if (!isApproved) {
        medalSignedContract
          .setApprovalForAll(signer.address, true, {
            gasPrice: feeData.gasPrice,
          })
          .then(() =>
            contract
              .safeTransferFrom(
                accountData?.address,
                PUBLIC_ADDRESS,
                name,
                1,
                [],
                { gasPrice: feeData.gasPrice }
              )
              .then(() => {
                start();
                setMedal(name);
              })
              .catch((err: any) => console.error(err))
          )
          .catch((err: any) => console.error(err));
      } else {
        contract
          .safeTransferFrom(accountData?.address, PUBLIC_ADDRESS, name, 1, [], {
            gasPrice: feeData.gasPrice,
          })
          .then(() => {
            start();
            setMedal(name);
          });
      }
    }
  };

  useEffect(() => {
    if (accountData && accountData.address) {
      balanceOfOne();
      balanceOfTwo();
      balanceOfThree();
    }
  }, []);

  const balanceOfOne = useCallback(() => {
    contractMedals
      .balanceOf(accountData?.address, 1)
      .then((r: BigNumber) => setBalanceOne(r.toNumber()));
  }, []);
  const balanceOfTwo = useCallback(() => {
    contractMedals
      .balanceOf(accountData?.address, 2)
      .then((r: BigNumber) => setBalanceTwo(r.toNumber()));
  }, []);
  const balanceOfThree = useCallback(() => {
    contractMedals
      .balanceOf(accountData?.address, 3)
      .then((r: BigNumber) => setBalanceThree(r.toNumber()));
  }, []);

  const eventCb = (event: string, data: any) => {
    console.log(`Received event ${event}: `, data);

    if (event === EVENT_GAME_OVER || event === EVENT_GAME_WON) {
      setShowRestart(true);
    } else if (event === EVENT_GAME_STARTED) {
      setShowStart(false);
    }
  };

  const start = () => {
    setShowMedals(false);
    setIsLoading(false);
    startGame(selectedShip, eventCb);
  };

  return (
    <section className="bg-violet w-screen min-h-screen">
      <div className="container mx-auto flex flex-col justify-start items-center pt-[60px] lg:pt-[120px] relative w-screen min-h-screen">
        <SelectShipModal
          isOpen={showSelectShip}
          links={links}
          close={() => setShowSelectShip(false)}
          selectShip={(ship: string) => {
            setShowSelectShip(false);
            onSelectedShip(ship);
          }}
        />
        <div
          className="flex flex-row justify-center items-center"
          id="tank-canvas-parent"
        >
          {isLoading && (
            <Triangle
              ariaLabel="loading-indicator"
              color="#fff"
              width={130}
              height={130}
            />
          )}
        </div>
        {showStart && (
          <div className="absolute bottom-[60px] lg:bottom-[120px]">
            <PrimaryButton title="Start Game" onClick={onStartGameClick} full />
          </div>
        )}

        {showRestart && (
          <div className="absolute bottom-[60px] lg:bottom-[120px]">
            <PrimaryButton
              title="Restart"
              onClick={() => {
                setShowRestart(false);
                restart();
              }}
              full
            />
          </div>
        )}

        {showMedals && (
          <div className="absolute bottom-[60px] lg:bottom-[120px] flex flex-col justify-start items-center">
            <div className=" flex flex-row justify-center items-center">
              <div className="flex flex-col justify-start items-center">
                <PrimaryButton
                  title={`Rookie ${balanceOne}`}
                  onClick={() => selectedPowerUp(1)}
                />
                <img src="assets/images/common/1.png" className="h-36" />
              </div>
              <span className="mx-4" />
              <div className="flex flex-col justify-start items-center">
                <PrimaryButton
                  title={`Veteran ${balanceTwo}`}
                  onClick={() => selectedPowerUp(2)}
                />
                <img src="assets/images/common/2.png" className="h-36" />
              </div>
              <span className="mx-4" />
              <div className="flex flex-col justify-start items-center">
                <PrimaryButton
                  title={`John Rambo ${balanceThree}`}
                  onClick={() => selectedPowerUp(3)}
                />
                <img src="assets/images/common/3.png" className="h-36" />
              </div>
            </div>
            <PrimaryButton
              full
              title={`Start anyway`}
              onClick={() => start()}
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default Tank;
