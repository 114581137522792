import React from 'react';
import { Route, Routes } from 'react-router-dom';
import RequireAuth from './components/RequireAuth';
import Home from './pages/Home';
import Tank from './pages/Tank';
import SpaceInvaders from './pages/SpaceInvaders';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route
        path="/space-invaders"
        element={
          <RequireAuth>
            <SpaceInvaders />
          </RequireAuth>
        }
      />
      <Route
        path="/tank"
        element={
          <RequireAuth>
            <Tank />
          </RequireAuth>
        }
      />
    </Routes>
  );
}

export default App;
