import React from 'react';

interface IPrimaryButton {
  title: string;
  full?: boolean;
  className?: string;
  onClick: () => void;
}

const PrimaryButton: React.FC<IPrimaryButton> = ({
  title,
  full = false,
  className,
  onClick,
}) => {
  return (
    <button
      onClick={() => onClick()}
      className={`btn ${className}`}
      style={{ width: full ? 310 : 200 }}
    >
      <span className="btnWrapper" style={{ width: full ? 310 : 200 }}>
        <span className={`btnText text-lg text-white font-semibold`}>
          {title}
        </span>
        <img
          loading="lazy"
          src="/assets/images/common/glass.png"
          alt="glass"
          className="btnGlass"
          width="59px"
          height="50px"
        />
      </span>
    </button>
  );
};

export default PrimaryButton;
