/* eslint-disable */

//import Phaser from 'phaser-ce';
var game;
var eventCb = null;
var shipData = null;

const EVENT_GAME_WON = 'gameWon';
const EVENT_MEDAL_ACHIEVED = 'medalAchieved';
const EVENT_GAME_OVER = 'gameOver';

const SHIP_SCALE = 50.0 / 256.0;
const LIVES_SCALE = 30.0 / 256.0;

export const startGame = function (cb, ship) {
  eventCb = cb;
  game = new Phaser.Game(800, 600, Phaser.AUTO, 'space-invaders-canvas', {
    preload: preload,
    create: create,
    update: update,
    render: render,
  });
  shipData = ship;
  return game;
};

function fireEvent(eventTpe, data = undefined) {
  if (eventCb) {
    eventCb(eventTpe, data);
  }
}

function preload() {
  game.load.crossOrigin = true;

  game.load.image('bullet', 'assets/bullet.png');
  game.load.image('enemyBullet', 'assets/enemy-bullet.png');
  game.load.spritesheet('invader', 'assets/invader32x32x4.png', 32, 32);
  // game.load.image('ship', 'assets/player.png');
  game.load.image('ship', shipData);
  game.load.spritesheet('kaboom', 'assets/explode.png', 128, 128);
  game.load.image('starfield', 'assets/starfield.png');
  game.load.image('background', 'assets/background2.png');
}

var player;
var aliens;
var bullets;
var bullet;
var enemyBullets;
var bulletTime = 0;
var cursors;
var fireButton;
var explosions;
var starfield;
var score = 0;
var scoreString = '';
var scoreText;
var lives;
var live;
var enemyBullet;
var firingTimer = 0;
var stateText;
var livingEnemies = [];
var level = 1;

function create() {
  game.scale.scaleMode = Phaser.ScaleManager.USER_SCALE;

  if (window.innerWidth < 1500) {
    game.scale.setUserScale(0.8, 0.8, 0, 0);
  } else {
    game.scale.setUserScale(1.2, 1.2, 0, 0);
  }

  game.physics.startSystem(Phaser.Physics.ARCADE);
  game.scale.pageAlignHorizontally = true;
  game.scale.pageAlignVertically = true;
  game.scale.refresh();

  //  The scrolling starfield background
  starfield = game.add.tileSprite(0, 0, 800, 600, 'starfield');

  //  Our bullet group
  bullets = game.add.group();
  bullets.enableBody = true;
  bullets.physicsBodyType = Phaser.Physics.ARCADE;
  bullets.createMultiple(30, 'bullet');
  bullets.setAll('anchor.x', 0.5);
  bullets.setAll('anchor.y', 1);
  bullets.setAll('outOfBoundsKill', true);
  bullets.setAll('checkWorldBounds', true);

  // The enemy's bullets
  enemyBullets = game.add.group();
  enemyBullets.enableBody = true;
  enemyBullets.physicsBodyType = Phaser.Physics.ARCADE;
  enemyBullets.createMultiple(30, 'enemyBullet');
  enemyBullets.setAll('anchor.x', 0.5);
  enemyBullets.setAll('anchor.y', 1);
  enemyBullets.setAll('outOfBoundsKill', true);
  enemyBullets.setAll('checkWorldBounds', true);

  //  The hero!
  player = game.add.sprite(400, 500, 'ship');
  player.anchor.setTo(0.5, 0.5);
  player.scale.setTo(SHIP_SCALE, SHIP_SCALE);
  game.physics.enable(player, Phaser.Physics.ARCADE);

  //  The baddies!
  aliens = game.add.group();
  aliens.enableBody = true;
  aliens.physicsBodyType = Phaser.Physics.ARCADE;

  createAliens();

  //  The score
  scoreString = 'Score : ';
  scoreText = game.add.text(10, 10, scoreString + score, {
    font: '34px Arial',
    fill: '#fff',
  });

  //  Lives
  lives = game.add.group();
  game.add.text(game.world.width - 100, 10, 'Lives : ', {
    font: '34px Arial',
    fill: '#fff',
  });

  //  Text
  stateText = game.add.text(game.world.centerX, game.world.centerY, ' ', {
    font: '84px Arial',
    fill: '#fff',
  });
  stateText.anchor.setTo(0.5, 0.5);
  stateText.visible = false;

  for (var i = 0; i < 3; i++) {
    var ship = lives.create(game.world.width - 100 + 30 * i, 60, 'ship');
    ship.anchor.setTo(0.5, 0.5);
    ship.angle = 90;
    ship.alpha = 0.4;
    ship.scale.setTo(LIVES_SCALE, LIVES_SCALE);
  }

  //  An explosion pool
  explosions = game.add.group();
  explosions.createMultiple(30, 'kaboom');
  explosions.forEach(setupInvader, this);

  //  And some controls to play the game with
  cursors = game.input.keyboard.createCursorKeys();
  fireButton = game.input.keyboard.addKey(Phaser.Keyboard.SPACEBAR);
}

function createAliens() {
  for (var y = 0; y < 4; y++) {
    for (var x = 0; x < 10; x++) {
      var alien = aliens.create(x * 48, y * 50, 'invader');
      alien.anchor.setTo(0.5, 0.5);
      alien.animations.add('fly', [0, 1, 2, 3], 20, true);
      alien.play('fly');
      alien.body.moves = false;
    }
  }

  aliens.x = 100;
  aliens.y = 50;

  //  All this does is basically start the invaders moving. Notice we're moving the Group they belong to, rather than the invaders directly.
  var tween = game.add
    .tween(aliens)
    .to({ x: 200 }, 2000, Phaser.Easing.Linear.None, true, 0, 1000, true);

  //  When the tween loops it calls descend
  tween.onLoop.add(descend, this);
}

function setupInvader(invader) {
  invader.anchor.x = 0.5;
  invader.anchor.y = 0.5;
  invader.animations.add('kaboom');
}

function descend() {
  let increment = level * 10;
  aliens.y += increment;
  //aliens.y += 10;
}

function update() {
  //  Scroll the background
  starfield.tilePosition.y += 2;

  if (player.alive) {
    //  Reset the player, then check for movement keys
    player.body.velocity.setTo(0, 0);

    if (cursors.left.isDown && player.position.x >= player.body.width) {
      player.body.velocity.x = -200;
    } else if (
      cursors.right.isDown &&
      player.position.x <= game.world.width - player.body.width
    ) {
      player.body.velocity.x = 200;
    }

    //  Firing?
    if (fireButton.isDown) {
      fireBullet();
    }

    if (game.time.now > firingTimer) {
      enemyFires();
    }

    //  Run collision
    game.physics.arcade.overlap(bullets, aliens, collisionHandler, null, this);
    game.physics.arcade.overlap(
      enemyBullets,
      player,
      enemyHitsPlayer,
      null,
      this
    );
  }
}

function render() {
  // for (var i = 0; i < aliens.length; i++)
  // {
  //     game.debug.body(aliens.children[i]);
  // }
}

function collisionHandler(bullet, alien) {
  //  When a bullet hits an alien we kill them both
  bullet.kill();
  alien.kill();

  //  Increase the score
  score += 20;
  scoreText.text = scoreString + score;

  //  And create an explosion :)
  var explosion = explosions.getFirstExists(false);
  explosion.reset(alien.body.x, alien.body.y);
  explosion.play('kaboom', 30, false, true);

  if (aliens.countLiving() == 0) {
    score += 200;
    scoreText.text = scoreString + score;

    enemyBullets.callAll('kill', this);
    stateText.fontSize = 50;
    //stateText.text = " You Won, \n Click to restart";
    switch (level) {
      case 1:
        stateText.text = " You Achieved 'Rookie', \n Click to restart";
        // window.mintAchievementToken(level); TODO - mint achievement
        fireEvent(EVENT_MEDAL_ACHIEVED, { level });
        level += 1;
        break;
      case 2:
        stateText.text = " You Achieved 'Veteran', \n Click to restart";
        // window.mintAchievementToken(level); TODO - mint achievement
        fireEvent(EVENT_MEDAL_ACHIEVED, { level });
        level += 1;
        break;
      case 3:
        stateText.text = " You Achieved 'John Rambo', \n Click to restart";
        // window.mintAchievementToken(level); TODO - mint achievement
        fireEvent(EVENT_MEDAL_ACHIEVED, { level });
        level += 1;
        break;
      default:
        stateText.text = ' You Won, \n Click to restart';
        //fireEvent(EVENT_MEDAL_ACHIEVED, {level});
        level += 1;
        break;
    }

    stateText.visible = true;

    //the "click to restart" handler
    // game.input.onTap.addOnce(restart, this);
    fireEvent(EVENT_GAME_WON);
  }
}

function enemyHitsPlayer(player, bullet) {
  bullet.kill();

  live = lives.getFirstAlive();

  if (live) {
    live.kill();
  }

  //  And create an explosion :)
  var explosion = explosions.getFirstExists(false);
  explosion.reset(player.body.x, player.body.y);
  explosion.play('kaboom', 30, false, true);

  // When the player dies
  if (lives.countLiving() < 1) {
    player.kill();
    enemyBullets.callAll('kill');
    var recordText = '';
    const record = localStorage.getItem('record');
    if (record) {
      if (record < score) {
        localStorage.setItem('record', score);
        recordText = score;
      } else {
        recordText = record;
      }
    } else {
      recordText = score;
      localStorage.setItem('record', score);
    }

    stateText.text =
      ' GAME OVER \n Score: ' +
      score +
      ' \n Best Score: ' +
      recordText +
      ' \n Click to restart';
    stateText.visible = true;
    lives.callAll('revive');
    level = 1;
    score = 0;
    //the "click to restart" handler
    // game.input.onTap.add(restart, this);

    fireEvent(EVENT_GAME_OVER);
  }
}

function enemyFires() {
  //  Grab the first bullet we can from the pool
  enemyBullet = enemyBullets.getFirstExists(false);

  livingEnemies.length = 0;

  aliens.forEachAlive(function (alien) {
    // put every living enemy in an array
    livingEnemies.push(alien);
  });

  if (enemyBullet && livingEnemies.length > 0) {
    var random = game.rnd.integerInRange(0, livingEnemies.length - 1);

    // randomly select one of them
    var shooter = livingEnemies[random];
    // And fire the bullet from this enemy
    enemyBullet.reset(shooter.body.x, shooter.body.y);

    game.physics.arcade.moveToObject(enemyBullet, player, 120);
    //firingTimer = game.time.now + 2000;
    firingTimer = game.time.now + 2000 / level;
  }
}

function fireBullet() {
  //  To avoid them being allowed to fire too fast we set a time limit
  if (game.time.now > bulletTime) {
    //  Grab the first bullet we can from the pool
    bullet = bullets.getFirstExists(false);

    if (bullet) {
      //  And fire it
      bullet.reset(player.x, player.y + 8);
      bullet.body.velocity.y = -400;
      bulletTime = game.time.now + 200;
    }
  }
}

function resetBullet(bullet) {
  //  Called if the bullet goes out of the screen
  bullet.kill();
}

export function restart() {
  //  A new level starts
  scoreText.text = scoreString + score;

  //resets the life count
  //lives.callAll('revive');
  //  And brings the aliens back from the dead :)
  aliens.removeAll();
  createAliens();

  //revives the player
  player.revive();
  //hides the text
  stateText.visible = false;
}

/* window.changePlayerSprite = function(uri)  {
    //game.load.image('custom', uri, true);
    game.load.image('custom', uri, true);
    game.load.onLoadComplete.add(function(){      
        player.loadTexture('custom')
        player.texture.frame.resize(50,50)
    },this)
    game.load.start();
    

    TODO load ship sprite

} */
