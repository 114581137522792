import React from 'react';
import ReactModal from 'react-modal';
import { FaTimes } from 'react-icons/fa';
import { Triangle } from 'react-loader-spinner';

interface ISelectShipModal {
  isOpen: boolean;
  links: Array<string>;
  close: () => void;
  selectShip: (ship: string) => void;
}

const SelectShipModal: React.FC<ISelectShipModal> = ({
  isOpen,
  links,
  close,
  selectShip,
}) => {
  return (
    <ReactModal
      isOpen={isOpen}
      ariaHideApp={false}
      onRequestClose={() => {
        close();
      }}
      style={{
        overlay: {
          backgroundColor: 'rgba(22, 22, 22, 0.7)',
          zIndex: 50,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          paddingLeft: 20,
          paddingRight: 20,
        },
        content: {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'transparent',
          border: 0,
        },
      }}
      className="py-2 w-full lg:w-auto"
    >
      <div className="bg-purple p-8 rounded-3xl flex flex-col justify-start items-center w-[544px] h-[404px]">
        <div className="self-end">
          <FaTimes color={'white'} onClick={close} />
        </div>
        <div className="flex flex-row justify-center items-start flex-wrap mt-5 w-[484px] h-[440px]">
          {links.length > 0 ? (
            links.map((link, index) => (
              <div
                className="w-[128px] h-[128px] rounded-3xl mx-4 border-2 border-white cursor-pointer"
                key={index}
                onClick={() => selectShip(link)}
              >
                <img
                  src={link}
                  alt={link}
                  className="w-[128px] h-[128px] rounded-3xl"
                />
              </div>
            ))
          ) : (
            <div className="self-center">
              <Triangle
                ariaLabel="loading-indicator"
                color="#fff"
                width={130}
                height={130}
              />
            </div>
          )}
        </div>
      </div>
    </ReactModal>
  );
};

export default SelectShipModal;
