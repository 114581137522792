import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useAccount, useConnect } from 'wagmi';
import MetamaskButton from '../components/MetamaskButton';
import PrimaryButton from '../components/PrimaryButton';
import { truncateAddress } from '../utils/helpers';

interface IHome {}

const Home: React.FC<IHome> = () => {
  const [{ data: connectData }, connect] = useConnect();
  const [{ data: accountData }] = useAccount({
    fetchEns: true,
  });

  const handleConnectMetamask = useCallback(async () => {
    if (connectData.connected) return;
    connect(connectData.connectors[0])
      .then((res) => console.log(res))
      .catch((err) => console.error(err.message));
  }, [connectData.connected, connectData.connectors, connect]);

  const handleConnect = useCallback(async () => {
    if (connectData.connected) return;
    connect(connectData.connectors[1])
      .then((res) => console.log(res))
      .catch((err) => console.error(err.message));
  }, [connectData.connected, connectData.connectors, connect]);

  return (
    <section className="bg-violet w-screen min-h-screen">
      <div className="container mx-auto flex flex-col justify-start items-center pt-[60px] lg:pt-[120px] relative w-screen min-h-screen">
        <h1 className=" text-white text-center text-4xl lg:text-6xl font-bold">
          NFT Factory Playground
        </h1>
        {accountData && (
          <h3 className="text-white mt-6">
            {truncateAddress(accountData.address)}
          </h3>
        )}

        {accountData && (
          <div className="flex flex-col lg:flex-row justify-center items-center mt-10">
            <Link
              to="/space-invaders"
              className=" border-2 rounded-[20px] border-white mx-4 flex flex-col justify-start items-center p-4"
            >
              <img
                src="assets/images/common/space.png"
                className="w-[210px] h-[210px] object-contain"
                alt="Space Invaders"
              />
              <h2 className="text-white text-2xl">Space Factory</h2>
            </Link>
            <Link
              to="/tank"
              className=" border-2 rounded-[20px] border-white mt-10 lg:mt-0 mx-4 flex flex-col justify-start items-center p-4"
            >
              <img
                src="assets/images/common/tank.png"
                className="w-[210px] h-[210px] object-contain"
                alt="Pacman"
              />
              <h2 className="text-white text-2xl">Factory Tank</h2>
            </Link>
          </div>
        )}

        {!accountData && (
          <>
            <div>
              <img
                src={'assets/images/common/experimentation.png'}
                alt="playground-image"
              />
            </div>
            <div className="absolute bottom-[60px] lg:bottom-[120px]">
              <PrimaryButton
                full
                title="Connect WalletConnect"
                onClick={handleConnect}
              />
              <MetamaskButton
                full
                title="Connect Metamask"
                onClick={handleConnectMetamask}
              />
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default Home;
